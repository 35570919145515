export class Enum {
  constructor(enums) {
    this.enums = enums;
    this.values = Object.values(enums);
    for (const prop in enums) {
      if (enums.hasOwnProperty(prop)) {
        this[prop] = enums[prop];
      }
    }
  }

  // Copy over enum value for other usage, i.e. Enum.UserGender.MALE

  getId = (enumValue) => {
    const ret = null;
    if (enumValue) {
      return enumValue.id;
    }
    return ret;
  };

  getEnum = (id) => {
    return this.values.find((e) => {
      return e.id === id;
    });
  };

  getEnumByKey = (value, valueKey) => {
    return this.values.find((e) => {
      return e[valueKey || 'id'] === value;
    });
  };

  getLabel = (id) => {
    const en = this.values.find((e) => {
      return e.id === id;
    });
    if (en) {
      return en.label;
    }
    return null;
  };

  getEnumFromValue = (value, valueName) => {
    return this.values.find((e) => {
      return e[valueName] === value;
    });
  };

  getIds = (enumValues) => {
    const ret = [];
    if (enumValues) {
      enumValues.forEach((val, i) => {
        const enumId = this.getId(val);
        if (enumId) {
          ret.push(enumId);
        }
      });
    }
    return ret;
  };

  getEnums = (ids) => {
    const ret = [];
    if (ids) {
      ids.forEach((enumId, i) => {
        const enumVal = this.getEnum(enumId);
        if (enumVal) {
          ret.push(enumVal);
        }
      });
    }
    return ret;
  };
}

export const PaymentType = new Enum({
  TRANSFERWISE: { id: 1, label: 'Bank Account' },
  PAYPAL: { id: 2, label: 'PayPal' },
});

export const BankAccountType = new Enum({
  CHECKING: { id: 1, label: 'Checking', country: 'all' },
  SAVINGS: { id: 2, label: 'Savings', country: 'all' },
  GENERAL: { id: 2, label: 'Current', country: 'JPY' },
});

export const IdDocumentType = new Enum({
  NATIONAL: { id: 1, label: 'National ID number', country: 'CRC' },
  FOREIGNER: { id: 2, label: 'Foreigner ID number', country: 'CRC' },
  DNI: { id: 'DNI', label: 'DNI', country: 'PEN' },
  RUC: { id: 'RUC', label: 'RUC', country: 'PEN' },
  C_EXT: { id: 'C_EXT', label: 'C_EXT', country: 'PEN' },
  PASSP: { id: 'PASSP', label: 'PASSP', country: 'PEN' },
});

export const Currency = new Enum({
  sgd: { id: 'SGD', code: 'SGD', label: 'Singapore Dollar', icon: 'S$', flag: 'sg' },
  eur_sgd: { id: 'EUR', code: 'EUR', label: 'Euro', icon: '€', flag: 'eu' },
  gbp_sgd: { id: 'GBP', code: 'GBP', label: 'British Pound', icon: '£', flag: 'gb' },
  usd_sgd: { id: 'USD', code: 'USD', label: 'US Dollar', icon: '$', flag: 'us' },
  aud_sgd: { id: 'AUD', code: 'AUD', label: 'Australian Dollar', icon: 'AU$', flag: 'au' },
  cad_sgd: { id: 'CAD', code: 'CAD', label: 'Canadian Dollar', icon: 'C$', flag: 'ca' },
  cny_sgd_100: { id: 'CNY', code: 'CNY', label: 'Chinese Renminbi', icon: '¥', flag: 'cn' },
  hkd_sgd_100: {
    id: 'HKD',
    code: 'HKD',
    label: 'Hong Kong Dollar',
    icon: 'HK$',
    flag: 'hk',
  },
  inr_sgd_100: { id: 'INR', code: 'INR', label: 'Indian Rupee', icon: '₹' },
  idr_sgd_100: {
    id: 'IDR',
    code: 'IDR',
    label: 'Indonesian Rupiah',
    icon: 'Rp',
    flag: 'in',
  },
  jpy_sgd_100: { id: 'JPY', code: 'JPY', label: 'Japanese Yen', icon: '¥', flag: 'jp' },
  krw_sgd_100: { id: 'KRW', code: 'KRW', label: 'Korean Won', icon: '₩', flag: 'kr' },
  myr_sgd_100: {
    id: 'MYR',
    code: 'MYR',
    label: 'Malaysian Ringgit',
    icon: 'RM',
    flag: 'my',
  },
  twd_sgd_100: {
    id: 'TWD',
    code: 'TWD',
    label: 'New Taiwan Dollar',
    icon: 'NT$',
    flag: 'tw',
  },
  nzd_sgd: { id: 'NZD', code: 'NZD', label: 'New Zealand Dollar', icon: 'NZ$', flag: 'nz' },
  php_sgd_100: { id: 'PHP', code: 'PHP', label: 'Philippine Peso', icon: '₱', flag: 'ph' },
  qar_sgd_100: { id: 'QAR', code: 'QAR', label: 'Qatar Riyal', icon: '﷼', flag: 'qa' },
  sar_sgd_100: {
    id: 'SAR',
    code: 'SAR',
    label: 'Saudi Arabia Riyal',
    icon: '﷼',
    flag: 'sa',
  },
  chf_sgd: { id: 'CHF', code: 'CHF', label: 'Swiss Franc', icon: 'CHF', flag: 'ch' },
  thb_sgd_100: { id: 'THB', code: 'THB', label: 'Thai Baht', icon: '฿', flag: 'th' },
  aed_sgd_100: { id: 'AED', code: 'AED', label: 'UAE Dirham', icon: 'د.إ;', flag: 'ae' },
  vnd_sgd_100: { id: 'VND', code: 'VND', label: 'Vietnamese Dong', icon: '₫', flag: 'vn' },
});

// Admin Version
// export const PaymentCurrency = new Enum({
//   aed: {
//     id: 'AED',
//     currency: 'AED',
//     country: 'AE',
//     name: 'United Arab Emirates dirham',
//     enabled: true,
//     hasValidation: true,
//   },
//   ars: {
//     id: 'ARS',
//     currency: 'ARS',
//     country: 'AR',
//     name: 'Argentine peso',
//     enabled: true,
//     hasValidation: false,
//   },
//   aud: {
//     id: 'AUD',
//     currency: 'AUD',
//     country: 'AU',
//     name: 'Australian dollar',
//     enabled: true,
//     hasValidation: true,
//   },
//   bdt: {
//     id: 'BDT',
//     currency: 'BDT',
//     country: 'BD',
//     name: 'Bangladeshi taka',
//     enabled: true,
//     hasValidation: false,
//   },
//   bgn: {
//     id: 'BGN',
//     currency: 'BGN',
//     country: 'BG',
//     name: 'Bulgarian lev',
//     enabled: true,
//     hasValidation: true,
//   },
//   cad: {
//     id: 'CAD',
//     currency: 'CAD',
//     country: 'CA',
//     name: 'Canadian dollar',
//     enabled: true,
//     hasValidation: true,
//   },
//   chf: {
//     id: 'CHF',
//     currency: 'CHF',
//     country: 'CH',
//     name: 'Swiss franc',
//     enabled: true,
//     hasValidation: true,
//   },
//   clp: {
//     id: 'CLP',
//     currency: 'CLP',
//     country: 'CL',
//     name: 'Chilean peso',
//     enabled: true,
//     hasValidation: false,
//   },
//   cny: {
//     id: 'CNY',
//     currency: 'CNY',
//     country: 'CN',
//     name: 'Chinese yuan',
//     enabled: true,
//     hasValidation: true,
//   },
//   crc: {
//     id: 'CRC',
//     currency: 'CRC',
//     country: 'CR',
//     name: 'Costa Rican colón',
//     enabled: true,
//     hasValidation: false,
//   },
//   czk: {
//     id: 'CZK',
//     currency: 'CZK',
//     country: 'CZ',
//     name: 'Czech koruna',
//     enabled: true,
//     hasValidation: false,
//   },
//   dkk: {
//     id: 'DKK',
//     currency: 'DKK',
//     country: 'DK',
//     name: 'Danish krone',
//     enabled: true,
//     hasValidation: true,
//   },
//   egp: {
//     id: 'EGP',
//     currency: 'EGP',
//     country: 'EG',
//     name: 'Egyptian pound',
//     enabled: true,
//     hasValidation: false,
//   },
//   eur: {
//     id: 'EUR',
//     currency: 'EUR',
//     country: 'EU',
//     name: 'Euro',
//     enabled: true,
//     hasValidation: true,
//   },
//   gbp: {
//     id: 'GBP',
//     currency: 'GBP',
//     country: 'GB',
//     name: 'British pound',
//     enabled: true,
//     hasValidation: true,
//   },
//   gel: {
//     id: 'GEL',
//     currency: 'GEL',
//     country: 'GE',
//     name: 'Georgian lari',
//     enabled: true,
//     hasValidation: true,
//   },
//   ghs: {
//     id: 'GHS',
//     currency: 'GHS',
//     country: 'GH',
//     name: 'Ghanaian cedi',
//     enabled: true,
//     hasValidation: false,
//   },
//   hkd: {
//     id: 'HKD',
//     currency: 'HKD',
//     country: 'HK',
//     name: 'Hong Kong dollar',
//     enabled: true,
//     hasValidation: false,
//   },
//   hrk: {
//     id: 'HRK',
//     currency: 'HRK',
//     country: 'HR',
//     name: 'Croatian kuna',
//     enabled: true,
//     hasValidation: true,
//   },
//   huf: {
//     id: 'HUF',
//     currency: 'HUF',
//     country: 'HU',
//     name: 'Hungarian forint',
//     enabled: true,
//     hasValidation: true,
//   },
//   idr: {
//     id: 'IDR',
//     currency: 'IDR',
//     country: 'ID',
//     name: 'Indonesian rupiah',
//     enabled: true,
//     hasValidation: false,
//   },
//   ils: {
//     id: 'ILS',
//     currency: 'ILS',
//     country: 'IL',
//     name: 'Israeli shekel',
//     enabled: true,
//     hasValidation: true,
//   },
//   inr: {
//     id: 'INR',
//     currency: 'INR',
//     country: 'IN',
//     name: 'Indian rupee',
//     enabled: true,
//     hasValidation: true,
//   },
//   jpy: {
//     id: 'JPY',
//     currency: 'JPY',
//     country: 'JP',
//     name: 'Japanese yen',
//     enabled: true,
//     hasValidation: false,
//   },
//   kes: {
//     id: 'KES',
//     currency: 'KES',
//     country: 'KE',
//     name: 'Kenyan shilling',
//     enabled: true,
//     hasValidation: false,
//   },
//   krw: {
//     id: 'KRW',
//     currency: 'KRW',
//     country: 'KR',
//     name: 'South Korean won',
//     enabled: true,
//     hasValidation: false,
//   },
//   lkr: {
//     id: 'LKR',
//     currency: 'LKR',
//     country: 'LK',
//     name: 'Sri Lankan rupee',
//     enabled: true,
//     hasValidation: false,
//   },
//   mad: {
//     id: 'MAD',
//     currency: 'MAD',
//     country: 'MA',
//     name: 'Moroccan dirham',
//     enabled: true,
//     hasValidation: false,
//   },
//   mxn: {
//     id: 'MXN',
//     currency: 'MXN',
//     country: 'MX',
//     name: 'Mexican peso',
//     enabled: true,
//     hasValidation: false,
//   },
//   myr: {
//     id: 'MYR',
//     currency: 'MYR',
//     country: 'MY',
//     name: 'Malaysian ringgit',
//     enabled: true,
//     hasValidation: false,
//   },
//   ngn: {
//     id: 'NGN',
//     currency: 'NGN',
//     country: 'NG',
//     name: 'Nigerian naira',
//     enabled: true,
//     hasValidation: false,
//   },
//   nok: {
//     id: 'NOK',
//     currency: 'NOK',
//     country: 'NO',
//     name: 'Norwegian krone',
//     enabled: true,
//     hasValidation: true,
//   },
//   npr: {
//     id: 'NPR',
//     currency: 'NPR',
//     country: 'NP',
//     name: 'Nepalese rupee',
//     enabled: true,
//     hasValidation: false,
//   },
//   nzd: {
//     id: 'NZD',
//     currency: 'NZD',
//     country: 'NZ',
//     name: 'New Zealand dollar',
//     enabled: true,
//     hasValidation: true,
//   },
//   pen: {
//     id: 'PEN',
//     currency: 'PEN',
//     country: 'PE',
//     name: 'Peruvian nuevo sol',
//     enabled: true,
//     hasValidation: false,
//   },
//   php: {
//     id: 'PHP',
//     currency: 'PHP',
//     country: 'PH',
//     name: 'Philippine peso',
//     enabled: true,
//     hasValidation: false,
//   },
//   pkr: {
//     id: 'PKR',
//     currency: 'PKR',
//     country: 'PK',
//     name: 'Pakistani rupee',
//     enabled: true,
//     hasValidation: true,
//   },
//   pln: {
//     id: 'PLN',
//     currency: 'PLN',
//     country: 'PL',
//     name: 'Polish złoty',
//     enabled: true,
//     hasValidation: true,
//   },
//   ron: {
//     id: 'RON',
//     currency: 'RON',
//     country: 'RO',
//     name: 'Romanian leu',
//     enabled: true,
//     hasValidation: true,
//   },
//   rub: {
//     id: 'RUB',
//     currency: 'RUB',
//     country: 'RU',
//     name: 'Russian rouble',
//     enabled: true,
//     hasValidation: false,
//   },
//   sek: {
//     id: 'SEK',
//     currency: 'SEK',
//     country: 'SE',
//     name: 'Swedish krona',
//     enabled: true,
//     hasValidation: true,
//   },
//   sgd: {
//     id: 'SGD',
//     currency: 'SGD',
//     country: 'SG',
//     name: 'Singapore dollar',
//     enabled: true,
//     hasValidation: false,
//   },
//   thb: {
//     id: 'THB',
//     currency: 'THB',
//     country: 'TH',
//     name: 'Thai baht',
//     enabled: true,
//     hasValidation: true,
//   },
//   try: {
//     id: 'TRY',
//     currency: 'TRY',
//     country: 'TR',
//     name: 'Turkish lira',
//     enabled: true,
//     hasValidation: true,
//   },
//   tzs: {
//     id: 'TZS',
//     currency: 'TZS',
//     country: 'TZ',
//     name: 'Tanzanian shilling',
//     enabled: true,
//     hasValidation: false,
//   },
//   uah: {
//     id: 'UAH',
//     currency: 'UAH',
//     country: 'UA',
//     name: 'Ukrainian hryvnia',
//     enabled: true,
//     hasValidation: false,
//   },
//   ugx: {
//     id: 'UGX',
//     currency: 'UGX',
//     country: 'UG',
//     name: 'Ugandan shilling',
//     enabled: true,
//     hasValidation: false,
//   },
//   usd: {
//     id: 'USD',
//     currency: 'USD',
//     country: 'US',
//     name: 'United States dollar',
//     enabled: true,
//     hasValidation: true,
//   },
//   uyu: {
//     id: 'UYU',
//     currency: 'UYU',
//     country: 'UY',
//     name: 'Uruguayan peso',
//     enabled: true,
//     hasValidation: false,
//   },
//   vnd: {
//     id: 'VND',
//     currency: 'VND',
//     country: 'VN',
//     name: 'Vietnamese dong',
//     enabled: true,
//     hasValidation: true,
//   },
//   xof: {
//     id: 'XOF',
//     currency: 'XOF',
//     country: 'XO',
//     name: 'West African CFA franc',
//     enabled: true,
//     hasValidation: false,
//   },
// });

// Frontend Version
// this is called PaymentCurrency in Admin but was already updated
export const BankAccountCurrency = new Enum({
  aed: {
    id: 'AED',
    currency: 'AED',
    country: 'AE',
    name: 'United Arab Emirates dirham',
    enabled: true,
    hasValidation: true,
  },
  ars: {
    id: 'ARS',
    currency: 'ARS',
    country: 'AR',
    name: 'Argentine peso',
    enabled: true,
    hasValidation: false,
  },
  aud: {
    id: 'AUD',
    currency: 'AUD',
    country: 'AU',
    name: 'Australian dollar',
    enabled: true,
    hasValidation: true,
  },
  bdt: {
    id: 'BDT',
    currency: 'BDT',
    country: 'BD',
    name: 'Bangladeshi taka',
    enabled: true,
    hasValidation: false,
  },
  bgn: {
    id: 'BGN',
    currency: 'BGN',
    country: 'BG',
    name: 'Bulgarian lev',
    enabled: true,
    hasValidation: true,
  },
  brl: {
    id: 'BRL',
    currency: 'BRL',
    country: 'BR',
    name: 'Brazilian real',
    enabled: false, // Disabled as bank branches are empty
    hasValidation: false,
  },
  bwp: {
    id: 'BWP',
    currency: 'BWP',
    country: 'BW',
    name: 'Botswanan pula',
    enabled: false, // Disabled as it's added for new Wise API only
    hasValidation: false,
  },
  cad: {
    id: 'CAD',
    currency: 'CAD',
    country: 'CA',
    name: 'Canadian dollar',
    enabled: true,
    hasValidation: true,
  },
  chf: {
    id: 'CHF',
    currency: 'CHF',
    country: 'CH',
    name: 'Swiss franc',
    enabled: true,
    hasValidation: true,
  },
  clp: {
    id: 'CLP',
    currency: 'CLP',
    country: 'CL',
    name: 'Chilean peso',
    enabled: true,
    hasValidation: false,
  },
  cny: {
    id: 'CNY',
    currency: 'CNY',
    country: 'CN',
    name: 'Chinese yuan',
    enabled: true,
    hasValidation: true,
  },
  cop: {
    id: 'COP',
    currency: 'COP',
    country: 'CO',
    name: 'Colombian peso',
    enabled: false, // Disabled as it's added for new Wise API only
    hasValidation: false,
  },
  crc: {
    id: 'CRC',
    currency: 'CRC',
    country: 'CR',
    name: 'Costa Rican colón',
    enabled: true,
    hasValidation: false,
  },
  czk: {
    id: 'CZK',
    currency: 'CZK',
    country: 'CZ',
    name: 'Czech koruna',
    enabled: true,
    hasValidation: false,
  },
  dkk: {
    id: 'DKK',
    currency: 'DKK',
    country: 'DK',
    name: 'Danish krone',
    enabled: true,
    hasValidation: true,
  },
  egp: {
    id: 'EGP',
    currency: 'EGP',
    country: 'EG',
    name: 'Egyptian pound',
    enabled: true,
    hasValidation: false,
  },
  eur: {
    id: 'EUR',
    currency: 'EUR',
    country: 'EU',
    name: 'Euro',
    enabled: true,
    hasValidation: true,
  },
  fjd: {
    id: 'FJD',
    currency: 'FJD',
    country: 'FJ',
    name: 'Fijian dollar',
    enabled: false, // Disabled as it's added for new Wise API only
    hasValidation: false,
  },
  gbp: {
    id: 'GBP',
    currency: 'GBP',
    country: 'GB',
    name: 'British pound',
    enabled: true,
    hasValidation: true,
  },
  gel: {
    id: 'GEL',
    currency: 'GEL',
    country: 'GE',
    name: 'Georgian lari',
    enabled: true,
    hasValidation: true,
  },
  ghs: {
    id: 'GHS',
    currency: 'GHS',
    country: 'GH',
    name: 'Ghanaian cedi',
    enabled: true,
    hasValidation: false,
  },
  hkd: {
    id: 'HKD',
    currency: 'HKD',
    country: 'HK',
    name: 'Hong Kong dollar',
    enabled: true,
    hasValidation: false,
  },
  hrk: {
    id: 'HRK',
    currency: 'HRK',
    country: 'HR',
    name: 'Croatian kuna',
    enabled: true,
    hasValidation: true,
  },
  huf: {
    id: 'HUF',
    currency: 'HUF',
    country: 'HU',
    name: 'Hungarian forint',
    enabled: true,
    hasValidation: true,
  },
  idr: {
    id: 'IDR',
    currency: 'IDR',
    country: 'ID',
    name: 'Indonesian rupiah',
    enabled: true,
    hasValidation: false,
  },
  ils: {
    id: 'ILS',
    currency: 'ILS',
    country: 'IL',
    name: 'Israeli shekel',
    enabled: true,
    hasValidation: true,
  },
  inr: {
    id: 'INR',
    currency: 'INR',
    country: 'IN',
    name: 'Indian rupee',
    enabled: true,
    hasValidation: true,
  },
  jpy: {
    id: 'JPY',
    currency: 'JPY',
    country: 'JP',
    name: 'Japanese yen',
    enabled: true,
    hasValidation: false,
  },
  kes: {
    id: 'KES',
    currency: 'KES',
    country: 'KE',
    name: 'Kenyan shilling',
    enabled: true,
    hasValidation: false,
  },
  krw: {
    id: 'KRW',
    currency: 'KRW',
    country: 'KR',
    name: 'South Korean won',
    enabled: true,
    hasValidation: false,
  },
  lkr: {
    id: 'LKR',
    currency: 'LKR',
    country: 'LK',
    name: 'Sri Lankan rupee',
    enabled: true,
    hasValidation: false,
  },
  mad: {
    id: 'MAD',
    currency: 'MAD',
    country: 'MA',
    name: 'Moroccan dirham',
    enabled: true,
    hasValidation: false,
  },
  mxn: {
    id: 'MXN',
    currency: 'MXN',
    country: 'MX',
    name: 'Mexican peso',
    enabled: true,
    hasValidation: false,
  },
  myr: {
    id: 'MYR',
    currency: 'MYR',
    country: 'MY',
    name: 'Malaysian ringgit',
    enabled: true,
    hasValidation: false,
  },
  ngn: {
    id: 'NGN',
    currency: 'NGN',
    country: 'NG',
    name: 'Nigerian naira',
    enabled: true,
    hasValidation: false,
  },
  nok: {
    id: 'NOK',
    currency: 'NOK',
    country: 'NO',
    name: 'Norwegian krone',
    enabled: true,
    hasValidation: true,
  },
  npr: {
    id: 'NPR',
    currency: 'NPR',
    country: 'NP',
    name: 'Nepalese rupee',
    enabled: true,
    hasValidation: false,
  },
  nzd: {
    id: 'NZD',
    currency: 'NZD',
    country: 'NZ',
    name: 'New Zealand dollar',
    enabled: true,
    hasValidation: true,
  },
  pen: {
    id: 'PEN',
    currency: 'PEN',
    country: 'PE',
    name: 'Peruvian nuevo sol',
    enabled: true,
    hasValidation: false,
  },
  php: {
    id: 'PHP',
    currency: 'PHP',
    country: 'PH',
    name: 'Philippine peso',
    enabled: true,
    hasValidation: false,
  },
  pkr: {
    id: 'PKR',
    currency: 'PKR',
    country: 'PK',
    name: 'Pakistani rupee',
    enabled: true,
    hasValidation: true,
  },
  pln: {
    id: 'PLN',
    currency: 'PLN',
    country: 'PL',
    name: 'Polish złoty',
    enabled: true,
    hasValidation: true,
  },
  ron: {
    id: 'RON',
    currency: 'RON',
    country: 'RO',
    name: 'Romanian leu',
    enabled: true,
    hasValidation: true,
  },
  rub: {
    id: 'RUB',
    currency: 'RUB',
    country: 'RU',
    name: 'Russian rouble',
    enabled: true,
    hasValidation: false,
  },
  sek: {
    id: 'SEK',
    currency: 'SEK',
    country: 'SE',
    name: 'Swedish krona',
    enabled: true,
    hasValidation: true,
  },
  sgd: {
    id: 'SGD',
    currency: 'SGD',
    country: 'SG',
    name: 'Singapore dollar',
    enabled: true,
    hasValidation: false,
  },
  thb: {
    id: 'THB',
    currency: 'THB',
    country: 'TH',
    name: 'Thai baht',
    enabled: true,
    hasValidation: true,
  },
  try: {
    id: 'TRY',
    currency: 'TRY',
    country: 'TR',
    name: 'Turkish lira',
    enabled: true,
    hasValidation: true,
  },
  tzs: {
    id: 'TZS',
    currency: 'TZS',
    country: 'TZ',
    name: 'Tanzanian shilling',
    enabled: true,
    hasValidation: false,
  },
  uah: {
    id: 'UAH',
    currency: 'UAH',
    country: 'UA',
    name: 'Ukrainian hryvnia',
    enabled: true,
    hasValidation: false,
  },
  ugx: {
    id: 'UGX',
    currency: 'UGX',
    country: 'UG',
    name: 'Ugandan shilling',
    enabled: true,
    hasValidation: false,
  },
  usd: {
    id: 'USD',
    currency: 'USD',
    country: 'US',
    name: 'United States dollar',
    enabled: true,
    hasValidation: true,
  },
  uyu: {
    id: 'UYU',
    currency: 'UYU',
    country: 'UY',
    name: 'Uruguayan peso',
    enabled: true,
    hasValidation: false,
  },
  vnd: {
    id: 'VND',
    currency: 'VND',
    country: 'VN',
    name: 'Vietnamese dong',
    enabled: true,
    hasValidation: true,
  },
  xof: {
    id: 'XOF',
    currency: 'XOF',
    country: 'XO',
    name: 'West African CFA franc',
    enabled: true,
    hasValidation: false,
  },
  zar: {
    id: 'ZAR',
    currency: 'ZAR',
    country: 'ZA',
    name: 'South African rand',
    enabled: false, // Disabled as there is no response in swift code endpoint
    hasValidation: false,
  },
  zmw: {
    id: 'ZMW',
    currency: 'ZMW',
    country: 'ZM',
    name: 'Zambian kwacha',
    enabled: false, // Disabled as it's added for new Wise API only
    hasValidation: false,
  },
});

export const PaymentCurrency = new Enum({
  sgd: {
    id: 'sgd',
    currency: 'SGD',
    name: 'Singapore Dollar',
    icon: 'S$',
    flag: 'sg',
  },
  eur: {
    id: 'eur',
    currency: 'EUR',
    name: 'Euro',
    icon: '€',
    flag: 'eu',
  },
  gbp: {
    id: 'gbp',
    currency: 'GBP',
    name: 'British Pound',
    icon: '£',
    flag: 'gb',
  },
  usd: {
    id: 'usd',
    currency: 'USD',
    name: 'US Dollar',
    icon: '$',
    flag: 'us',
  },
  aud: {
    id: 'aud',
    currency: 'AUD',
    name: 'Australian Dollar',
    icon: 'AU$',
    flag: 'au',
  },
  cad: {
    id: 'cad',
    currency: 'CAD',
    name: 'Canadian Dollar',
    icon: 'C$',
    flag: 'ca',
  },
  cny: {
    id: 'cny',
    currency: 'CNY',
    name: 'Chinese Renminbi',
    icon: '¥',
    flag: 'cn',
  },
  hkd: {
    id: 'hkd',
    currency: 'HKD',
    name: 'Hong Kong Dollar',
    icon: 'HK$',
    flag: 'hk',
  },
  inr: {
    id: 'inr',
    currency: 'INR',
    name: 'Indian Rupee',
    icon: '₹',
    flag: 'in',
  },
  idr: {
    id: 'idr',
    currency: 'IDR',
    name: 'Indonesian Rupiah',
    icon: 'Rp',
    flag: 'id',
  },
  jpy: {
    id: 'jpy',
    currency: 'JPY',
    name: 'Japanese Yen',
    icon: '¥',
    flag: 'jp',
  },
  krw: {
    id: 'krw',
    currency: 'KRW',
    name: 'Korean Won',
    icon: '₩',
    flag: 'kr',
  },
  myr: {
    id: 'myr',
    currency: 'MYR',
    name: 'Malaysian Ringgit',
    icon: 'RM',
    flag: 'my',
  },
  nzd: {
    id: 'nzd',
    currency: 'NZD',
    name: 'New Zealand Dollar',
    icon: 'NZ$',
    flag: 'nz',
  },
  php: {
    id: 'php',
    currency: 'PHP',
    name: 'Philippine Peso',
    icon: '₱',
    flag: 'ph',
  },
  chf: {
    id: 'chf',
    currency: 'CHF',
    name: 'Swiss France',
    icon: 'CHF',
    flag: 'ch',
  },
  thb: {
    id: 'thb',
    currency: 'THB',
    name: 'Thai Baht',
    icon: '฿',
    flag: 'th',
  },
  vnd: {
    id: 'vnd',
    currency: 'VND',
    name: 'Vietnamese Dong',
    icon: '₫',
    flag: 'vn',
  },
  // 'brl': { id: 'BR', currency: 'BRL', name: 'Brazilian Real', icon: '', flag: 'br' },
  // 'Bulgarian Lev'
  // 'Costa Rican Colon'
  // 'Croatian Kuna'
  // 'Czech Koruna'
  // 'Danish Krone'
  // 'Hungarian Forint'
  // 'Norwegian Krone'
  // 'Polish Zloty'
  // 'Romanian Leu'
  // 'Swedish Krona'
  // 'Tanzanian Shilling'
  // 'Turkish Lira'
  // 'Ugandan Shilling'
  // 'Uruguayan Peso'
  // 'West African CFA Franc'
});

export const ProjectDeadlineStatus = new Enum({
  ACTIVE: { id: 1, label: 'Active' },
  COMPLETE: { id: 2, label: 'Complete' },
});

export const Site = new Enum({
  CONTENTCO: { id: 1, label: 'Content.co' },
  HIREDIGITAL: { id: 2, label: 'Hire Digital' },
});

export const PopupType = {
  FULL_PAGE_MODAL: 'full',
  BOTTOM_PAGE_MODAL: 'bottom',
};

export const PortfolioType = {
  USER: 0,
  ORGANIZATION: 1,
  TEAM: 2,
  RESUME: 3,
  PORTFOLIO: 4,
  PUBLIC_RESUME: 5,
  PUBLIC_PORTFOLIO: 6,
  TALENT: 7,
  TEAM_SLUG: 8,
};

export const BriefRegistrationStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  SUBMITTED: { id: 2, label: 'Submitted' },
});

export const CallStatus = new Enum({
  SCHEDULED: { id: 1, label: 'Scheduled' },
  CANCELLED: { id: 2, label: 'Cancelled' },
  RESCHEDULED: { id: 3, label: 'Rescheduled' },
  DRAFT: { id: 4, label: 'Draft' },
});

export const PreferredPlatform = new Enum({
  PHONE: { id: 1, label: 'Phone' },
  ZOOM: { id: 2, label: 'Zoom' },
  SKYPE: { id: 3, label: 'Skype' },
  WEB_CALL: { id: 4, label: 'Web call' },
});

export const CallPreferredPlatform = new Enum({
  PHONE: { id: 1, label: 'Phone' },
  WEB_CALL: { id: 4, label: 'Web call' },
});

export const ContractTypes = new Enum({
  FULL_TIME: { id: 1, label: 'Full Time' },
  PART_TIME: { id: 2, label: 'Part Time' },
});

// Marketing Version
// export const OrganizationType = new Enum({
//   ENTERPRISE: { id: 1, label: 'Enterprise' },
//   PROFESSIONAL: { id: 2, label: 'Professional' },
// });

// Admin Version
export const OrganizationType = new Enum({
  ENTERPRISE: { id: 1, label: 'Enterprise' },
  PROFESSIONAL: { id: 2, label: 'Professional' },
  LITE: { id: 3, label: 'Lite' },
});

// Marketing and Admin Version
export const EngagementStatus = new Enum({
  // DRAFT: { id: 1, label: 'Draft' },
  MATCH: { id: 2, label: 'Match' },
  TRIAL: { id: 3, label: 'Trial' },
  ACTIVE: { id: 4, label: 'Active' },
  COMPLETED: { id: 5, label: 'Completed' },
  CANCELLED: { id: 6, label: 'Cancelled' },
});

// Frontend Version
export const FrontendEngagementStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  MATCH: { id: 2, label: 'Accepting Talents' },
  TRIAL: { id: 3, label: 'Trial' },
  ACTIVE: { id: 4, label: 'Active' },
  COMPLETED: { id: 5, label: 'Completed' },
  CANCELLED: { id: 6, label: 'Cancelled' },
});

// Marketing Version
// export const Role = new Enum({
//   CLIENT: { id: 1, label: 'Client' },
//   TALENT: { id: 2, label: 'Talent' },
//   TALENT_REVIEW: { id: 3, label: 'Talent Review Admin' },
//   TALENT_REVIEW_LIMITED: { id: 9, label: 'Talent Review Limited' },

//   TALENT_SEARCH_ADMIN: { id: 7, label: 'Talent Search Admin' },
//   TALENT_SEARCH_BRAND_RATES: {
//     id: 10,
//     label: 'Talent Search Limited (Brand Only)',
//   },
//   TALENT_SEARCH_TALENT_RATES: {
//     id: 13,
//     label: 'Talent Search Limited (Talent Only)',
//   },

//   BRIEF_SALES: { id: 6, label: 'Brief Sales Admin' },
//   BRIEF_SALES_LIMITED: { id: 11, label: 'Brief Sales Limited' },
//   BRIEF_PLACEMENT: { id: 8, label: 'Brief Placement Admin' },
//   BRIEF_PLACEMENT_LIMITED: { id: 12, label: 'Brief Placement Limited' },
//   BRIEF_ADMIN: { id: 4, label: 'Brief Full Admin' },

//   CLIENT_ADMIN: { id: 14, label: 'Client Admin' },
//   CLIENT_ADMIN_LIMITED: { id: 15, label: 'Client Limited' },
//   ADMIN: { id: 5, label: 'Full Admin' },
// });

// Frontend Version
// export const Role = new Enum({
//   CLIENT: { id: 1, label: 'Client' },
//   TALENT: { id: 2, label: 'Talent' },
//   TALENT_REVIEW: { id: 3, label: 'Talent Review Admin' },
//   TALENT_REVIEW_LIMITED: { id: 9, label: 'Talent Review Limited' },

//   TALENT_SEARCH_ADMIN: { id: 7, label: 'Talent Search Admin' },
//   TALENT_SEARCH_BRAND_RATES: {
//     id: 10,
//     label: 'Talent Search Limited (Brand Only)',
//   },
//   TALENT_SEARCH_TALENT_RATES: {
//     id: 13,
//     label: 'Talent Search Limited (Talent Only)',
//   },

//   BRIEF_SALES: { id: 6, label: 'Brief Sales Admin' },
//   BRIEF_SALES_LIMITED: { id: 11, label: 'Brief Sales Limited' },
//   BRIEF_PLACEMENT: { id: 8, label: 'Brief Placement Admin' },
//   BRIEF_PLACEMENT_LIMITED: { id: 12, label: 'Brief Placement Limited' },
//   BRIEF_ADMIN: { id: 4, label: 'Brief Full Admin' },

//   CLIENT_ADMIN: { id: 14, label: 'Client Admin' },
//   CLIENT_ADMIN_LIMITED: { id: 15, label: 'Client Limited' },
//   ADMIN: { id: 5, label: 'Full Admin' },
// });

// Admin Version
export const Role = new Enum({
  CLIENT: { id: 1, label: 'Client' },
  TALENT: { id: 2, label: 'Talent' },
  TALENT_REVIEW: { id: 3, label: 'Talent Review Admin' },
  TALENT_REVIEW_LIMITED: { id: 9, label: 'Talent Review Limited' },

  TALENT_SEARCH_ADMIN: { id: 7, label: 'Talent Search Admin' },
  TALENT_SEARCH_BRAND_RATES: {
    id: 10,
    label: 'Talent Search Limited (Brand Only)',
  },
  TALENT_SEARCH_TALENT_RATES: {
    id: 13,
    label: 'Talent Search Limited (Talent Only)',
  },

  BRIEF_SALES: { id: 6, label: 'Brief Sales Admin' },
  BRIEF_SALES_LIMITED: { id: 11, label: 'Brief Sales Limited' },
  BRIEF_PLACEMENT: { id: 8, label: 'Brief Placement Admin' },
  BRIEF_PLACEMENT_LIMITED: { id: 12, label: 'Brief Placement Limited' },
  BRIEF_ADMIN: { id: 4, label: 'Brief Full Admin' },

  CLIENT_ADMIN: { id: 14, label: 'Client Admin' },
  CLIENT_ADMIN_LIMITED: { id: 15, label: 'Client Limited' },

  ASSIGNMENT_ADMIN: { id: 16, label: 'Assignment Admin' },
  ASSIGNMENT_ADMIN_LIMITED: { id: 17, label: 'Assignment Admin Limited' },
  FINANCE: { id: 18, label: 'Finance' },
  ACCOUNT_MANAGER: { id: 19, label: 'Account Manager' },
  ADMIN: { id: 5, label: 'Full Admin' },
});

export const ServiceRateType = new Enum({
  // Notes on "multiplier":
  // The key is the ID of NeededHoursType or DurationUnits (perId), and the value is the multiplier.
  // The multiplier value is the conversion from ServiceRateType to NeededHoursType or DurationUnits.
  // For more info and the table of multiplier, see https://hiredigital.atlassian.net/browse/HD-16
  HOUR: {
    id: 1,
    label: 'per hour',
    name: 'Per Hour',
    shortLabel: '/hr',
    // Key as follows: 2: days, 3: weeks, 4: months, 5: quarters: 6: years
    // Zero value will coerce the multiplier to the number of hours.
    multiplier: { 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 },
  },
  DAY: {
    id: 2,
    label: 'per day',
    name: 'Per Day',
    shortLabel: '/day',
    // These are the number of workdays on average in a week, month, quarter or year.
    // It doesn't count weekends. However, it counts workday holidays so it's not accurate.
    multiplier: {
      // one day = 1 workday
      2: 1,
      // one week in workdays
      3: 5,
      // one month in workdays
      4: 21.74,
      // one quarter in workdays
      5: 65.22,
      // one year in workdays
      6: 260.9,
    },
  },
  WEEK: {
    id: 3,
    label: 'per week',
    name: 'Per Week',
    shortLabel: '/week',
    multiplier: {
      // one day (no multiplier)
      2: 1,
      // one week = 1 week
      3: 1,
      // one month in weeks
      4: 4.33,
      // one quarter in weeks
      5: 13,
      // one year in weeks
      6: 52,
    },
  },
  MONTH: {
    id: 4,
    label: 'per month',
    name: 'Per Month',
    shortLabel: '/month',
    multiplier: {
      // one day (no multiplier)
      2: 1,
      // one week (no multiplier)
      3: 1,
      // one month = 1 month
      4: 1,
      // one quarter in months
      5: 3,
      // one year in months
      6: 12,
    },
  },
  QUARTER: {
    id: 5,
    label: 'per quarter',
    name: 'Per Quarter',
    shortLabel: '/qtr',
    // a year has four quarters. no multipliers for the rest.
    multiplier: { 2: 1, 3: 1, 4: 1, 5: 1, 6: 4 },
  },
  YEAR: {
    id: 6,
    label: 'per year',
    name: 'Per Year',
    shortLabel: '/yr',
    // no multipiers for year
    multiplier: { 2: 1, 3: 1, 4: 1, 5: 1, 6: 1 },
  },
  PROJECT: {
    id: 7,
    label: 'per project',
    name: 'Per Project',
    shortLabel: '/project',
    // no multiplier
    multiplier: { 2: 1, 3: 1, 4: 1, 5: 1, 6: 1 },
  },
});

export const NeededHoursType = new Enum({
  DAY: ServiceRateType.DAY,
  WEEK: ServiceRateType.WEEK,
  MONTH: ServiceRateType.MONTH,
  QUARTER: ServiceRateType.QUARTER,
  YEAR: ServiceRateType.YEAR,
});

export const DurationUnits = new Enum({
  // perId is the corresponding ID in ServiceRateType and NeededHoursType
  DAYS: { id: 1, label: 'days', name: 'day', perId: 2 },
  WEEKS: { id: 2, label: 'weeks', name: 'week', perId: 3 },
  MONTHS: { id: 3, label: 'months', name: 'month', perId: 4 },
  QUARTER: { id: 4, label: 'quarters', name: 'quarter', perId: 5 },
  YEARS: { id: 5, label: 'years', name: 'year', perId: 6 },
});

export const TimeRate = new Enum({
  HOUR: { id: 1, label: 'per hour' },
  DAY: { id: 2, label: 'per day' },
  WEEK: { id: 3, label: 'per week' },
  MONTH: { id: 4, label: 'per month' },
  QUARTER: { id: 5, label: 'per quarter' },
  YEAR: { id: 6, label: 'per year' },
});

export const VisibilityOption = new Enum({
  PRIVATE_OPEN: { id: 1, label: 'Invited Talents - Open' },
  PRIVATE_CONFIDENTIAL: { id: 2, label: 'Invited Talents - Confidential' },
  // NETWORK_OPEN: { id: 3, label: 'Entire Network - Open' },
  // NETWORK_CONFIDENTIAL: { id: 4, label: 'Entire Network - Confidential' },
  PUBLIC_OPEN: { id: 5, label: 'Public - Open' },
  PUBLIC_CONFIDENTIAL: { id: 6, label: 'Public - Confidential' },
});

export const ProjectStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  IN_PROGRESS: { id: 2, label: 'In Progress' },
  IN_REVIEW: { id: 3, label: 'In Review' },
  COMPLETED: { id: 4, label: 'Completed' },
  CANCELLED: { id: 5, label: 'Cancelled' },
  ARCHIVED: { id: 6, label: 'Archived' },
});

// Admin Version
// export const AgreementStatus = new Enum({
//   DRAFT: { id: 1, label: 'Draft' },
//   SENT: { id: 2, label: 'Created' },
//   IN_PROCESS: { id: 3, label: 'In Process' },
//   SIGNED: { id: 4, label: 'Signed' },
//   CANCELLED: { id: 5, label: 'Cancelled' },
// });

// Frontend Version
export const AgreementStatus = new Enum({
  DRAFT: {
    id: 1,
    label: 'Draft',
    userMessage: 'Please take a moment to review the terms and sign the agreement.',
  },
  SENT: {
    id: 2,
    label: 'Created',
    userMessage: 'Please take a moment to review the terms and sign the agreement.',
  },
  IN_PROCESS: {
    id: 3,
    label: 'In Process',
    userMessage: 'Please take a moment to review the terms and sign the agreement.',
  },
  SIGNED: {
    id: 4,
    label: 'Signed',
    userMessage: 'You have already signed your platform agreement. You can view your copy here.',
  },
  CANCELLED: {
    id: 5,
    label: 'Cancelled',
    userMessage: 'Please take a moment to review the terms and sign the agreement.',
  },
});

// export const DeadlineStatus = new Enum({
//   ACTIVE: { id: 1, label: 'Active' },
//   COMPLETE: { id: 2, label: 'Complete' },
// });

export const InvoiceStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  SUBMITTED: { id: 2, label: 'Awaiting Approval' },
  DELETED: { id: 3, label: 'Deleted' },
  AUTHORISED: { id: 4, label: 'Awaiting Payment' },
  PAID: { id: 5, label: 'Paid' },
  VOIDED: { id: 6, label: 'Voided' },
});

export const MemberInvitationStatus = new Enum({
  CREATED: { id: 1, label: 'Created' },
  SENT: { id: 2, label: 'Sent' },
  ACCEPTED: { id: 3, label: 'Accepted' },
  CANCELLED: { id: 4, label: 'Cancelled' },
  RESEND: { id: 5, label: 'Resent' },
  REJECTED: { id: 6, label: 'Rejected' },
});

export const StripeCurrency = new Enum({
  SGD: { id: 'sgd', label: 'SGD' },
  USD: { id: 'usd', label: 'USD' },
});

export const BaseCountry = new Enum({
  SINGAPORE: { id: 1, label: 'Singapore (Bolt Media Pte Ltd)' },
  UNITED_STATES: { id: 2, label: 'United States (Hire Digital Corporation)' },
});

export const PendingPrepaymentStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  SUBMITTED: { id: 2, label: 'Submitted' },
  PAID: { id: 3, label: 'Paid' },
});

export const PortfolioItemType = new Enum({
  URL: { id: 1, label: 'URL', name: 'Link' },
  UPLOAD: { id: 2, label: 'Upload', name: 'Upload' },
  CASE_STUDY: { id: 3, label: 'Case Study', name: 'Case Study' },
});

export const ReviewStatus = new Enum({
  NEEDS_REVIEW: { id: 1, label: 'To Review' },
  ACCEPTED: { id: 2, label: 'Approved' },
  REJECTED: { id: 3, label: 'Rejected' },
});

export const QuoteStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  DELETED: { id: 2, label: 'Deleted' },
  SENT: { id: 3, label: 'Sent' },
  DECLINED: { id: 4, label: 'Declined' },
  ACCEPTED: { id: 5, label: 'Accepted' },
  INVOICED: { id: 6, label: 'Invoiced' },
});

export const RatingStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  SUBMITTED: { id: 2, label: 'Submitted' },
  ARCHIVED: { id: 3, label: 'Archived' },
});

export const RatingType = new Enum({
  CLIENT_RATE_TALENT: { id: 1, label: 'Client Rating Talent' },
  TALENT_RATE_CLIENT: { id: 2, label: 'Talent Rating Client' },
  ADMIN_RATE_TALENT: { id: 3, label: 'Admin Rating Talent' },
  ADMIN_RATE_CLIENT: { id: 4, label: 'Admin Rating Client' },
});

export const ServiceRateStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft (Visible)' },
  NEEDS_REVIEW: { id: 2, label: 'Needs Review (Visible)' },
  LIVE: { id: 3, label: 'Live (Visible)' },
  LIVE_HIDDEN: { id: 6, label: 'Live (Hidden)' },
  ARCHIVED: { id: 4, label: 'Archived (Hidden)' },
  MIGRATED: { id: 5, label: 'Migrated (Hidden)' },
});

export const TransferStatus = new Enum({
  incoming_payment_waiting: { id: 'incoming_payment_waiting', label: 'Awaiting Payment' },
  waiting_recipient_input_to_proceed: {
    id: 'waiting_recipient_input_to_proceed',
    label: 'Awaiting Input',
  },
  processing: { id: 'processing', label: 'Processing' },
  funds_converted: { id: 'funds_converted', label: 'Funds Converted' },
  outgoing_payment_sent: { id: 'outgoing_payment_sent', label: 'Sent' },
  cancelled: { id: 'cancelled', label: 'Cancelled' },
  funds_refunded: { id: 'funds_refunded', label: 'Refunded' },
  bounced_back: { id: 'bounced_back', label: 'Bounced' },
  charged_back: { id: 'charged_back', label: 'Charged Back' },
  unknown: { id: 'unknown', label: 'Unknown' },
});

// Admin Version
export const AdminApplicationStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  SUBMITTED: {
    id: 2,
    label: 'Submitted',
    description:
      'These applications will not appear to clients, but appear to be submitted by talents',
  },
  SUBMITTED_HOLD: {
    id: 8,
    label: 'Submitted + Hold',
    description:
      'These applications will not appear to clients, but appear to be submitted by talents, but will not be part of the normal rejected workflow',
  },
  SUBMITTED_REVIEWED: {
    id: 7,
    label: 'Submitted + Reviewed',
    description: 'These applications will appear to clients',
  },
  INTERVIEW: { id: 3, label: 'Interview Pending' },
  INTERVIEW_SELECTED: { id: 9, label: 'Interview Scheduled' },
  ACCEPTED: { id: 4, label: 'Accepted' },
  REJECTED: { id: 5, label: 'Rejected' },
  WITHDRAWN: { id: 6, label: 'Withdrawn' },
});

// Frontend Version
export const ApplicationStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft', statusLabel: 'Draft' },
  SUBMITTED: { id: 2, label: 'Submitted', statusLabel: 'Applied' },
  INTERVIEW: { id: 3, label: 'Interview', statusLabel: 'Interview' },
  ACCEPTED: { id: 4, label: 'Accepted', statusLabel: 'Accepted' },
  REJECTED: { id: 5, label: 'Rejected', statusLabel: 'Not Accepted' },
  WITHDRAWN: { id: 6, label: 'Withdrawn', statusLabel: 'Closed' },
  SUBMITTED_REVIEWED: { id: 7, label: 'Submitted + Reviewed', statusLabel: 'Applied' },
  SUBMITTED_HOLD: { id: 8, label: 'Submitted + Hold', statusLabel: 'Applied' },
  INTERVIEW_SELECTED: { id: 9, label: 'Interview + Selected', statusLabel: 'Interview Scheduled' },
});

// Admin Version
// export const RateType = new Enum({
//   HOURLY: { id: 1, label: 'Hourly' },
//   PART_TIME: { id: 2, label: 'Part Time' },
//   FULL_TIME: { id: 3, label: 'Full Time' },
//   FIXED: { id: 4, label: 'Fixed' },
// });

// Frontend Version
export const RateType = new Enum({
  HOURLY: { id: 1, label: 'Hourly', value: 'per hour' },
  PART_TIME: { id: 2, label: 'Part Time', value: 'per week' },
  FULL_TIME: { id: 3, label: 'Full Time', value: 'per Hour' },
  FIXED: { id: 4, label: 'Fixed', value: 'per project' },
});

export const AssignmentStatus = new Enum({
  TRIAL: { id: 1, label: 'Trial' },
  PROJECT: { id: 2, label: 'Active' },
  INACTIVE: { id: 3, label: 'Inactive' },
  CLOSED: { id: 4, label: 'Closed' },
});

export const EngagementInvitationStatus = new Enum({
  CREATED: { id: 1, label: 'Draft' },
  SENT: { id: 2, label: 'Sent + Reviewed' },
  ACCEPTED: { id: 3, label: 'Accepted' },
  CANCELLED: { id: 4, label: 'Cancelled' },
  RESEND: { id: 5, label: 'Resend' },
  REJECTED: { id: 6, label: 'Rejected' },
});

// Admin Version
export const AdminBriefStage = new Enum({
  INFO: { id: 1, label: 'Initial Page' },
  PROJECT: { id: 2, label: 'Project Details' },
  CALL: { id: 3, label: 'Schedule Call' },
  CALL_REVIEW: { id: 4, label: 'Call Review' },
  AGREEMENT: { id: 7, label: 'Agreement' },
  DEPOSIT: { id: 8, label: 'Deposit' },
  EDIT_REVIEW: { id: 5, label: 'Edit Review' },
  CONFIRM: { id: 6, label: 'Confirm' },
  MATCH: { id: 9, label: 'Matching' },
  ASSIGNED: { id: 10, label: 'Assigned' },
});

export const BriefStage = new Enum({
  INFO: {
    id: 1,
    label: 'Requirements',
    dashboardLabel: 'Draft',
    name: 'Confirm Requirements',
    path: '/start',
  },
  PROJECT: {
    id: 2,
    label: 'Requirements',
    dashboardLabel: 'Draft',
    name: 'Brief Requirements',
    path: '/requirements',
  },
  CALL: {
    id: 3,
    label: 'Schedule Call',
    dashboardLabel: 'Schedule Call',
    name: 'Schedule Call',
    path: '/call',
  },
  CALL_REVIEW: {
    id: 4,
    label: 'Call Scheduled',
    dashboardLabel: 'Schedule Call',
    name: 'Call with Content.co',
    path: '/call',
  },
  AGREEMENT: {
    id: 7,
    label: 'Agreement',
    dashboardLabel: 'Confirmation',
    name: 'Agreement',
    path: '/agreement',
  },
  DEPOSIT: {
    id: 8,
    label: 'Deposit',
    dashboardLabel: 'Confirmation',
    name: 'Deposit',
    path: '/deposit',
  },
  EDIT_REVIEW: {
    id: 5,
    label: 'Brief Review',
    dashboardLabel: 'Confirmation',
    name: 'Brief Review',
    path: '/edit-review',
  },
  CONFIRM: {
    id: 6,
    label: 'Confirm Brief',
    dashboardLabel: 'Confirmation',
    name: 'Confirm Brief',
    path: '/overview',
  },
  MATCH: {
    id: 9,
    label: 'Matching',
    dashboardLabel: 'Matching to Talents',
    name: 'See suitable talents here',
    path: '/engagements',
  },
  ASSIGNED: {
    id: 10,
    label: 'Assigned',
    dashboardLabel: 'Assigned',
    name: 'Assigned',
    path: '/engagements',
  },
});

export const BriefStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  ACTIVE: { id: 2, label: 'Active' },
  CANCELLED: { id: 3, label: 'Cancelled' },
  CLOSED: { id: 4, label: 'Closed' },
});

export const EmailStatus = new Enum({
  SENT: { id: 0, label: 'Sent' },
  FAILED: { id: 1, label: 'Failed' },
  QUEUED: { id: 2, label: 'Queued' },
});

export const EmailTemplateBase = new Enum({
  STANDARD: { id: 1, label: 'Standard' },
  PLAIN: { id: 2, label: 'Plain' },
  NEW: { id: 3, label: 'New' },
});

export const OrganizationStatus = new Enum({
  ONBOARDING: { id: 1, label: 'Onboarding' },
  ACTIVE: { id: 2, label: 'Active' },
  REMOVED_BY_USER: { id: 3, label: 'User Declined' },
  REMOVED_BY_ADMIN: { id: 4, label: 'Admin Removed' },
  ARCHIVED: { id: 5, label: 'Archived' },
});

export const UserStatus = new Enum({
  ONBOARDING: { id: 1, label: 'Onboarding' },
  ACTIVE: { id: 2, label: 'Active' },
  NETWORK_ACTIVE: { id: 7, label: 'Active (+ Open Engagements)' },
  USER_DECLINED: { id: 3, label: 'User Removed' },
  ADMIN_REMOVED: { id: 4, label: 'Admin Removed' },
  LEGACY_ACTIVE: { id: 5, label: 'Legacy Active' },
  LEGACY_DISABLED: { id: 6, label: 'Legacy Disabled' },
});

export const MessageType = new Enum({
  TEXT: { id: 1, label: 'Text' },
  UPLOAD: { id: 2, label: 'Upload' },
  ACTION: { id: 3, label: 'Action' },
  ACTIVITY: { id: 4, label: 'Activity' },
  CONTENT: { id: 5, label: 'Content' },
  CONTENT_ANNOTATION: { id: 6, label: 'Annotation' },
  NESTED_DISCUSSION: { id: 7, label: 'Discussion' },
});

export const ContentType = new Enum({
  LINK: { id: 1, label: 'Link' },
  ATTACHMENT: { id: 2, label: 'Attachment' },
  TEXT: { id: 3, label: 'Text' },
});

export const UserRegistrationStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  SUBMITTED: { id: 2, label: 'Submitted' },
});

export const TalentRegistrationStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  SUBMITTED: { id: 2, label: 'Submitted' },
});

export const TeamStatus = new Enum({
  ONBOARDING: { id: 1, label: 'Onboarding' },
  ACTIVE: { id: 2, label: 'Active' },
  USER_DECLINED: { id: 3, label: 'User Removed' },
  ADMIN_REMOVED: { id: 4, label: 'Admin Removed' },
});

export const ConferenceStatus = new Enum({
  PENDING: { id: 1, label: 'Pending' },
  CONFIRMED: { id: 2, label: 'Confirmed' },
  COMPLETED: { id: 3, label: 'Completed' },
  CANCELLED: { id: 4, label: 'Cancelled' },
  DRAFT: { id: 5, label: 'Draft' },
  PENDING_RESCHEDULE: { id: 6, label: 'Reschedule' },
});

export const BriefType = new Enum({
  CALL: { id: 1, label: 'Call' },
  BRIEF: { id: 2, label: 'Brief' },
});

export const BriefSearchType = new Enum({
  TALENT: { id: 1, label: 'Skill' },
  FORMAT: { id: 2, label: 'Format' },
});

export const MessageMode = new Enum({
  CREATED: 1,
  UPDATED: 2,
  DELETED: 3,
});

export const ConferenceUserStatus = new Enum({
  PENDING: { id: 1, label: 'Request Availability', name: 'Request Availability', selectable: true },
  CONFIRMED: { id: 2, label: 'Confirmed', name: 'Availability Confirmed', selectable: false },
  NOT_REQUIRED: {
    id: 3,
    label: 'Don’t Request Availability',
    name: 'Don’t Request Availability',
    selectable: true,
  },
});

export const PointContactType = new Enum({
  EXISTING: { id: 1, label: 'Existing User' },
  CUSTOM: { id: 2, label: 'Custom' },
});

export const InvitationStatus = new Enum({
  CREATED: { id: 1, label: 'Created' },
  SENT: { id: 2, label: 'Pending' },
  ACCEPTED: { id: 3, label: 'Accepted' },
  CANCELLED: { id: 4, label: 'Cancelled' },
  RESEND: { id: 5, label: 'Resent' },
});

export const NotificationChoice = new Enum({
  NEVER: { id: 1, label: 'Never' },
  DAILY: { id: 2, label: 'Daily' },
  ALL: { id: 3, label: 'All' },
});

export const UserEngagementFilter = new Enum({
  ALL: { id: null, label: 'All' },
  INVITATION: { id: 1, label: 'Invitations' },
  APPLICATION: { id: 2, label: 'Applications' },
  ENGAGEMENT: { id: 3, label: 'Open Engagements' },
  ASSIGNMENT: { id: 4, label: 'Assignments' },
});

export const ProjectFilter = new Enum({
  ALL: { id: null, label: 'All', status: null },
  DRAFT: { id: '1', label: 'Draft', status: 1 },
  IN_PROGRESS: { id: '2,3', label: 'In Progress', status: [2, 3] },
  COMPLETED: { id: '4', label: 'Complete', status: 4 },
});

export const BriefFilter = new Enum({
  ALL: { id: null, stage: null, label: 'All', key: 'stage' },
  DRAFT: { id: '1,2', stage: [1, 2], label: 'Draft', key: 'stage' },
  SCHEDULE_CALL: { id: '3,4', stage: [3, 4], label: 'Schedule Call', key: 'stage' },
  CONFIRMATION: { id: '5,6,7,8', stage: [5, 6, 7, 8], label: 'Confirmation', key: 'stage' },
  MATCHING: { id: '9', stage: 9, label: 'Matching To Talents', key: 'stage' },
  ASSIGNED: { id: '10', stage: 10, label: 'Assigned', key: 'stage' },
});

export const ProspectStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  CONVERTED: { id: 2, label: 'Converted' },
  SUBMITTED: { id: 3, label: 'Submitted' },
});

export const ProjectMemberStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  INVITED: { id: 2, label: 'Invited' },
  ACTIVE: { id: 3, label: 'Active' },
  REJECTED: { id: 4, label: 'Rejected' },
  CANCELLED: { id: 5, label: 'Cancelled' },
  EXPIRED: { id: 6, label: 'Expired' },
});

export const JobType = new Enum({
  CONTRACTED_TO_FULLTIME: { id: 1, label: 'Contracted to Full Time' },
  TEMPORARY: { id: 2, label: 'Temporary' },
  PARTTIME_TO_FULLTIME: { id: 3, label: 'Part Time to Full Time' },
  CONTRACTED: { id: 4, label: 'Contracted' },
  FULL_TIME: { id: 5, label: 'Full Time' },
  PART_TIME: { id: 6, label: 'Part Time' },
  TEMPORARY_TO_FULLTIME: { id: 7, label: 'Temporary to Full Time' },
});

export const JobStatus = new Enum({
  OPEN: { id: 1, label: 'Open' },
  DRAFTING: { id: 2, label: 'Drafting' },
  ON_HOLD: { id: 3, label: 'On Hold' },
  CLOSED: { id: 4, label: 'Closed' },
  CANCELLED: { id: 5, label: 'Cancelled' },
});

export const ConferencePlatform = new Enum({
  GOOGLE: { id: 1, label: 'Google Meet' },
  MICROSOFT: { id: 2, label: 'Microsoft Teams' },
});
