import { useState } from 'react';
import { generateUserAgreement } from '@apis/users';
import { getAgreementLink } from '@apis/agreements';
import { AgreementStatus } from '@hiredigital/lib/helpers/enum';
import { useUser } from '@context/user';
import { openUrl } from '@hiredigital/lib/helpers/utils';
import Card from '@hiredigital/ui/Card';
import Button from '@hiredigital/ui/Button';
import toast from 'react-hot-toast';

const UserAgreement = ({ ...props }) => {
  const user = useUser();
  const [agreement] = useState(user?.agreement);
  const [isLoading, setIsLoading] = useState(false);

  const handleViewAgreement = async (e) => {
    setIsLoading(true);
    try {
      // const callback = router.asPath;
      const callback = window.location.href;
      if (!agreement?.uuid) {
        const { data: docusignUrl } = await generateUserAgreement(user?.uuid, { callback });
        // window.location.href = data.url;
        await openUrl(docusignUrl, { target: '_self', verifyUrl: true });
        return;
      }

      const pdfUrl = agreement?.attachment;
      if (pdfUrl) {
        await openUrl(pdfUrl, { verifyUrl: true });
        return;
      }

      const docusignUrl = await getAgreementLink('users', user?.uuid, agreement.id, {
        callback,
      });
      await openUrl(docusignUrl, { target: '_self', verifyUrl: true });
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 404) {
        toast.error("Agreement couldn't be found!");
      } else {
        toast.error("Something's wrong! Please contact us to investigate.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    agreement && (
      <Card>
        <Card.Header title={`Platform Agreement`}></Card.Header>
        <Card.Item>
          {agreement
            ? `The platform agreement is required for all talents on Hire Digital. ${
                AgreementStatus.getEnum(agreement.status).userMessage
              }`
            : `The platform agreement is required for all talents on Hire Digital. Please take a moment to review the terms and sign the agreement.`}
        </Card.Item>
        <Card.Footer right>
          <Button
            type={Button.Type.BLUE}
            onClick={handleViewAgreement}
            isLoading={isLoading}>{`View Agreement`}</Button>
        </Card.Footer>
      </Card>
    )
  );
};

export default UserAgreement;
