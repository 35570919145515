import { Fragment, useMemo, useRef, memo } from 'react';
import classNames from 'classnames';
import IconCaret from '@hiredigital/ui/Icon/icons/caret.inline.svg';

// import Icon from '@hiredigital/ui/Icon/Icon';
import Loader from '@hiredigital/ui/Loader';

import Styles from './Table.module.scss';
import MultiLineEllipsis from '@hiredigital/ui/MultiLineEllipsis';
import Button from '@hiredigital/ui/Button/Button';
// This component will handle the rendering of the table only

const Table = memo(
  ({
    // required
    listRef,
    list,
    meta,
    emptyTitle,
    emptySubtitle,
    emptyState,
    isLoading,

    // not sure
    rowClass,
    cellClass,
    tableClass,
    headerRowClass,
    headerCellClass,
    emptyContainerClass,
    loadingContainerClass,
    rowHeight = 45,
    headerRowHeight = 40,
    loadData,
    columns,
    prepareRow,
    getTableProps,
    getTableBodyProps,
    getTrProps = () => {},
    headerGroups,
    rows,
    renderRowSubComponent,
    onSaveCallback,
    onDeleteCallback,
    showClearSearch = false,
    onClearSearch,
    ...props
  }) => {
    // Use the state and functions returned from useTable to build your UI
    const defaultColumn = useMemo(
      () => ({
        width: 150,
      }),
      []
    );
    // const headerRowRef = useRef({});
    // const rowHeights = useRef({});

    const isItemLoaded = (index) => {
      if (list?.[index]?.id || list?.[index]?.uuid) {
        return true;
      } else {
        return false;
      }
    };

    const Row = memo(({ index, row }) => {
      const rowRef = useRef({});
      return (
        <tr
          {...row.getRowProps()}
          {...getTrProps(row)}
          ref={rowRef}
          className={classNames(Styles.row, rowClass)}
          style={{ height: rowHeight }}>
          {row.isExpanded && renderRowSubComponent ? (
            <td style={{ width: '100%' }}>
              {renderRowSubComponent({ row }, onSaveCallback, onDeleteCallback)}
            </td>
          ) : (
            row.cells.map((cell) => {
              // if truncate property is not provided(undefined) or if its null, it will be treated as true
              const shouldTruncate = cell?.column?.truncate === false ? false : true;
              const CellContainer = shouldTruncate ? MultiLineEllipsis : Fragment;
              return (
                <td
                  {...cell.getCellProps()}
                  className={classNames(Styles.cell, cellClass)}
                  title={cell.render('Header')}>
                  <CellContainer
                    {...(shouldTruncate &&
                      cell?.column?.truncateLine && { truncateLine: cell.column.truncateLine })}>
                    {cell.render('Cell')}
                  </CellContainer>
                </td>
              );
            })
          )}
        </tr>
      );
    });

    const RenderRow = (row, index) => {
      prepareRow(row);
      if (row && isItemLoaded(index)) {
        return <Row key={index} index={index} row={row} />;
      } else {
        return (
          <div
            key={index}
            className={classNames(Styles.loadingRow, Styles.row, rowClass)}
            index={index}>
            <Loader size={Loader.Size.SMALL} style={{ margin: '10px' }} />
          </div>
        );
      }
    };

    return (
      <div className={Styles.container} data-test-id='scroll-container'>
        <table {...getTableProps()} className={classNames(Styles.table, tableClass)}>
          <Fragment>
            {headerGroups.map((headerGroup, idx) => (
              <thead key={idx}>
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className={classNames(Styles.headerRow, headerRowClass)}
                  height={headerRowHeight}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={classNames(
                        Styles.headerCell,
                        headerCellClass,
                        column.isSorted && Styles.boldHeader
                      )}>
                      {column.render('Header')}

                      <span className={Styles.action}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <IconCaret className={classNames(Styles.toggle, Styles.down)} />
                          ) : (
                            <IconCaret className={classNames(Styles.toggle, Styles.up)} />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
            ))}
          </Fragment>
          <tbody {...getTableBodyProps()} className={Styles.body}>
            {rows?.map(RenderRow)}
          </tbody>
          {!isLoading && list.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={columns.length || 1}>
                  {/* <div>{emptyState || 'No items found'}</div> */}
                  <div className={classNames(Styles.emptyContainer, emptyContainerClass)}>
                    <h3 className={Styles.emptyTitle}>{emptyTitle}</h3>
                    <p className={Styles.emptySubtitle}>
                      {(typeof emptySubtitle == 'function' ? emptySubtitle?.() : emptySubtitle) ||
                        'No items found'}
                    </p>
                    {showClearSearch && (
                      <Button type={Button.Type.BLUE} onClick={() => onClearSearch?.()}>
                        {`Clear Search and Filters`}
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          )}
          {isLoading && (
            <tbody
              className={classNames(
                Styles.loadingContainer,
                loadingContainerClass,
                list.length && Styles.hasItems
              )}>
              <tr>
                <td colSpan={columns.length || 1}>
                  <div className={Styles.loading}>
                    <Loader />
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    );
  }
);

export default Table;
