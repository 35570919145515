import { authRequest } from './utils';

export const getAgreementLink = async (parent, uuid, agreementId) => {
  const url = `${process.env.MARKETING_ENDPOINT}/api/docusign`;
  const data = {
    resource: parent,
    resourceUuid: uuid,
    agreementId: agreementId,
  };
  const response = await authRequest.post(url, data);
  return `${process.env.MARKETING_ENDPOINT}/api/docusign?agreement=${response.data}`;
};
