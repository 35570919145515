import Menu from '@hiredigital/ui/Menu/Menu';
import IconEllipsis from '@hiredigital/ui/Icon/icons/more-vertical.inline.svg';
import Button from './Button';
import Styles from './Button.module.scss';
import classNames from 'classnames';

const SplitButton = ({
  children,
  more,
  direction,
  isLoading,
  type = Button.Type.GRAY,
  moreButtonClass,
  moreIconClass,
  mainButtonContainerClass,
  mainButtonContainerProps,
  ...props
}) => {
  const hasChildren = !!children;
  return (
    <Menu>
      <div
        className={classNames(Styles.splitBtnContainer, mainButtonContainerClass)}
        {...mainButtonContainerProps}>
        {hasChildren && (
          <Button isLoading={isLoading} type={type} className={Styles.mainBtn} {...props}>
            {children}
          </Button>
        )}
        <Menu.Button as='div' className={Styles.menuButtonContainer}>
          <Button
            {...(hasChildren && { type })}
            className={classNames(Styles.moreBtn, !hasChildren && Styles.default, moreButtonClass)}>
            <IconEllipsis
              data-test-id='split-button-more-icon'
              className={classNames(Styles.moreIcon, moreIconClass)}
            />
          </Button>
        </Menu.Button>
      </div>
      {more?.length > 0 && (
        <Menu.Items className={Styles.dropdownContainer} direction={direction}>
          {more.map(
            ({ text, ...rest }, idx) =>
              text && (
                <Menu.Item as='div' className={Styles.dropdownItem} {...rest} key={idx}>
                  {text}
                </Menu.Item>
              )
          )}
        </Menu.Items>
      )}
    </Menu>
  );
};

SplitButton.Type = Button.Type;

export default SplitButton;
